<template>
  <div class="live-container">
    <breadcrumb></breadcrumb>
    <div class="container-title">
      玩·非遗
    </div>
    <!-- <div class="live-category-box">
          <category @change="changeCategory" :categorys="categorys"></category>
      </div> -->
    <div class="search-box">
      <div class="drop-box">
        <el-dropdown @command='handleCommand'>
          <span class="el-dropdown-link">
            {{ type ? type : '活动类型' }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="item" v-for="item in dict.type" :key="item.id">{{ item.label }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      
      <div class="drop-box">
        <el-dropdown @command='handleCommand2'>
          <span class="el-dropdown-link">
            {{ isOnline ? isOnline : '活动形式' }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="item" v-for="item in dict.isOnline" :key="item.id">{{ item.label }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="drop-box">
        <el-dropdown @command='handleCommand3'>
          <span class="el-dropdown-link">
            {{ activityStatus ? activityStatus : '活动状态' }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="item" v-for="item in dict.activityStatus" :key="item.id">{{ item.label }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="drop-box">
        <input type="text" class="ipt" v-model="queryParams.title" value="" placeholder="请输入关键字" style="" />
      </div>
      <div class="drop-box btn" style="" @click="handleQuery">
        搜索
      </div>
      <div class="drop-box" style="width: 100px; background-size: 100% 100%;font-size: 16px;" @click="resetQuery">
        重置
      </div>

    </div>
    <list :list="list" imgHeight="220px" style="margin-top: 30px;" @toDetail="toDetail">
      <template #status="{item}" >
       <span class="status"  :style="{backgroundColor:item.showStatus===1?'':item.showStatus===2?'red':item.showStatus===3?'blue':'orange'}">{{ item.showStatus===1?'即将开始':item.showStatus===2?'已结束':item.showStatus===3?'正在报名...':'名额已满' }}</span>
        <!-- {{ haha }} -->
     </template>
      <template #title-img-mask>
       
        <img class="play-icon" src="../../assets/images/play.png" alt="">
      </template>
      <template #default="{ row }">
        <div>活动时间：{{ formatDate(row.startTime, 'yyyy-MM-dd') }} 至 {{ formatDate(row.endTime, 'MM-dd') }}</div>
        <div>活动地点：{{ row.address }}</div>
      </template>
    </list>
    <!-- 分页组件 -->
    <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="getList" />
    
    <!-- <pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList" /> -->
  </div>
</template>

<script>

import {
  getData, getDict
} from "@/api/order/order";
export default {
  name: 'wanList',
  data() {
    return {
      type: '',
      isOnline: '',
      activityStatus: '',
      dict: {
        type:[{label:'全部',value:''}],
        isOnline:[{label:'全部',value:''},{label:'线上活动',value:true},{label:'线下活动',value:false}],
        activityStatus:[{label:'全部',value:''},{label:'即将开始',value:0},{label:'报名中',value:1},{label:'报名已结束',value:2}]
      },
      queryParams: {
        pageNo: 1,//页码
        pageSize: 9,//每页条数
        type: null,
        isOnline: null,
        activityStatus: null,
        title: '',
      },
      categoryIndex: 0,//分类下标
      //分类
      categorys: [{
        name: '全部',
        status: 0
      }, {
        name: '正在直播',
        status: 1
      }, {
        name: '直播回放',
        status: 2
      }],
      pageNo: 1,//页码
      pageSize: 9,//每页条数
      total: 0,//总数量
      list: [],//列表
    }
  },
  methods: {
    resetQuery() {
      this.queryParams={
          pageNo: 1,//页码
          pageSize: 10,//每页条数
          type: null,
          isOnline: null,
          activityStatus: null,
          title:''
      }
      this.type=''
      this.isOnline=''
      this.activityStatus=''
      this.handleQuery();
     },
    handleQuery(e) { 
      this.queryParams.pageNo = 1;
                this.getList();
    },
    handleCommand(e) {
      this.type = e.label
      this.queryParams.type = e.value
    },
    handleCommand2(e) {
      this.isOnline = e.label
      this.queryParams.isOnline = e.value
    },
    handleCommand3(e) {
      this.activityStatus = e.label
      this.queryParams.activityStatus = e.value
    },
    getDictList() {
     getDict().then(res => { 
     
      this.dict.type=[{label:'全部',value:''},...res.data]
      })
    
    },
    /**
     * 详情
     */
    toDetail(item) {
      this.$router.push({path:'./detail/'+item.id})
      // console.log(item)
    },
    /**
     * 获取列表
     */
    getList() {
      let status = this.categorys[this.categoryIndex].status//当前分类
      getData(this.queryParams).then(res => {
        if (res.code == 0) {
          res.data.list.forEach(item => {
							if (!item.timeLimit) {
								//报名中

							} else {
								let nowDate = +new Date()
								// console.log(item.startTime>nowDate);

								if (item.startTime > nowDate) {
									// 即将开始
									item.showStatus = 1;

								} else if (item.endTime < nowDate) {

									//已结束
									item.showStatus = 2;
								} else {

									//正在报名
									if (item.quotaLimit) {

										// 判断票数
										if (item.quota === 0) {
											// 无票
											item.showStatus = 4;
										} else {
											// 报名中
											item.showStatus = 3;
										}
									} else {
										// 永远报名中
										item.showStatus = 3;
									}

								}

							}
						})

          this.list = res.data.list
          this.total = res.data.total
        }
      })
    },
    /**
     * 选择分类
     */
    changeCategory(index) {
      this.categoryIndex = index
      this.getList()
    }
  },
  created() {
    this.getDictList()
    //获取列表
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
.search-box {
  display: flex;
  justify-content: center;

  .drop-box {
    margin-left: 20px;
    display: flex;
    // align-content: center;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 50px;
    background: url('~@/assets/images/news-category-border.png') no-repeat center center scroll;
    background-size: contain;
    color: #979797;
    font-size: 14px;
    // background: url('../../assets/images/news-category-border.png') no-repeat center center scroll;
  }

  .ipt {
    display: inline-block;
    width: 120px;
    height: 40px;
    font-size: 16px;
  }

  .btn {
    background: url('~@/assets/images/news-category-border-active.png') no-repeat center center scroll;
    width: 100px;
    background-size: 100% 100%;
    // background-color: #fff;
    color: #fff;
    font-size: 16px;
  }

  .reset {
    width: 100px;
    background-size: 100% 100%;
    // background-color: #fff;
    // color: #fff;
    font-size: 16px;
    color: #000;
    background: url('~@/assets/images/news-category-border.png') no-repeat center center scroll;

  }


}

.el-dropdown {
  // width: 100%;
  display: flex;
  justify-content: space-between;

  // align-content: center;
  align-items: center;
}

.el-dropdown-link {
  text-align: center;
  width: 100px;
  line-height: 50px;
  font-size: 16px;
  cursor: pointer;
  word-wrap: break-word;
  /*强制换行*/
  overflow: hidden;
  /*超出隐藏*/
  text-overflow: ellipsis;
  /*隐藏后添加省略号*/
  white-space: nowrap;
  /*强制不换行*/

}

.el-icon-arrow-down {
  margin-left: 20px;
  // font-size: 16px;
}

.live-container {
  width: 1200px;
  margin: 0 auto;

  .container-title {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: #181B1C;
    font-family: XiaoWei-Regular;
  }
  .status {
  padding: 5px 10px;border-radius: 10px;font-size: 14px;; background-color: #E85984;color: #fff;
}
  .play-icon {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: .3s;
  }

  .list-item:hover {
    .play-icon {
      opacity: 0;
    }
  }

}</style>